import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import ContentNavigation from "../components/content-navigation"

export default function Poradnie({ data }) {
  const pages = data.pages.edges.map(({ node }) => ({
    type: node.type,
    title: node.title,
    slug: '/poradnie' + node.fields.slug
  }))
  console.log(pages)

  const podstawowaOpiekaMedyczna = pages.filter(({ type }) => type === "podstawowa-opieka-zdrowotna")
  const poradnieSpecjalistyczne = pages.filter(({ type }) => type === "poradnie-specjalistyczne")
  const poradnieSpecjalistycznePrywatne = pages.filter(({ type }) => type === "poradnie-specjalistyczne-prywatne")

  const seo = {
    title: "Poradnie specjalistyczne i podstawowej opieki zdrowotnej",
    description: "Podstawowa opieka zdrowotna, poradnie specjalistyczne oraz prywatne poradnie specjalistyczne w Medicus-Dukla",
    url: "https://medicusdukla.pl/poradnie"
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section data-g="container">
        <h1 className="a-heading-medium">Poradnie</h1>
        <br/><br data-g={'hide show@md'}/><br data-g={'hide show@md'}/>
        <ContentNavigation
          color="blue"
          title="Podstawowa opieka zdrowotna"
          pages={podstawowaOpiekaMedyczna}
          heading={title => (<h2>{title}</h2>)}
        />
        <ContentNavigation
          color="darkblue"
          title="Poradnie specjalistyczne NFZ"
          pages={poradnieSpecjalistyczne}
          heading={title => (<h2>{title}</h2>)}
        />
        <ContentNavigation
          color="pink"
          title="Poradnie specjalistyczne prywatne"
          pages={poradnieSpecjalistycznePrywatne}
          heading={title => (<h2>{title}</h2>)}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        pages: allPoradnieYaml {
            edges {
                node {
                    type
                    title
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
